import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import OngoingTemplate from '../components/projects/OngoingTemplate'

const useLeChaletData = () =>
  useStaticQuery(graphql`
  query {
    ongoingJson(slug: { eq: "le-chalet" }) {
      footer_desc
      short_desc
      slug
      rera
      title
      tagline
      callUsNumber
      chatCodeId
      logo {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      brouchureUrl
      video {
        type
        link
      }
      sections {
        title
        synopsis
        specification {
          a
          q
        }
        floor_plan {
          title
          pricing
          rera
          images {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        highlights
        gMapUrl
        address
        gallery {
          title
          images {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        project_updates {
          title
          images {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      banner {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      bg {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`);

export default () => {
  const data = useLeChaletData();

  return (
    <OngoingTemplate data={data} />
  );
};
